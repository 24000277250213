import styled, { css } from "styled-components"

export const StyledInputContainer = styled.div`
  margin-top: 2.5rem;

  &:first-child {
    margin-top: 0;
  }

  & > p {
    display: none;
    color: #ff8f35;
    font-size: 0.75rem;
  }

  @media only screen and (max-width: 1350px) {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;
    }
  }
`

export const StyledInput = styled.input`
  background: transparent;
  color: #ffffff;
  font-weight: 200;
  font-size: 1.25rem;
  width: 100%;
  line-height: 1.75rem;
  padding-bottom: 0.75rem;
  border: none;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
  -webkit-text-fill-color: var(--main-font-color) !important;
  caret-color: white;
  outline: none;

  ${({ hasError }) => hasError && css`
    border-bottom: 1px solid #FF8F35;
  `}

  &::placeholder {
    color: var(--main-font-color);
    font-weight: 200;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  &:focus-visible {
    border-bottom: 0.0625rem solid white;
  }

  &:-webkit-autofill {
    background-clip: text;
    -webkit-background-clip: text;
  }

  @media only screen and (max-width: 1350px) {
    padding-bottom: 0.5rem;

    &::placeholder {
      font-size: 1rem;
      line-height: 1.625rem;
    }
  }
`

export const StyledInputError = styled.span`
  color: #ff8f35;
  font-size: 0.75rem;
`;
