import React from "react"
import { StyledInputContainer, StyledInput, StyledInputError } from "./styled"

const Input = ({ name, type, placeholder, inputValue, handleInput, checkValidation, validationErrors }) => {
  return (
    <StyledInputContainer>
      <StyledInput
        type={type}
        placeholder={placeholder}
        value={inputValue}
        onChange={({ currentTarget: { value } }) => handleInput(value)}
        onBlur={() => checkValidation[name]()}
        hasError={!!validationErrors[name]}
       />
       {!!validationErrors[name] && (
        <StyledInputError>{validationErrors[name]}</StyledInputError>
       )}
    </StyledInputContainer>
  )
}

export default Input
