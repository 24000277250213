import { useState } from "react"
import { EMAIL_VALIDATION_REGEXP } from "@constants"
import SendCV from "../../../../../../utils/sendCV";
import { 
    DIGITS_VALIDATION_ERROR, 
    EMAIL_VALIDATION_ERROR, 
    FULL_NAME_VALIDATION_ERROR, 
    LATIN_CHARACTERS_VALIDATION_ERROR, 
    MOBILE_PHONE_VALIDATION_ERROR
} from "../../constants";

const useFormConfiguration = ({ vacancyId, setShowMessage }) => {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [cvState, setCvState] = useState({
    cv: null,
    showErrorMessageForCV: false,
  })
  const [agreement, setAgreement] = useState(false)

  const [validationChecks, setValidationChecks] = useState({
    fullName: false,
    email: false,
    phone: false,
  })

  const [validationErrors, setValidationErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
  })

  const clearForm = () => {
    setFullName("")
    setEmail("")
    setPhone("")
    setCvState({ cv: null, showErrorMessageForCV: false })
    setAgreement(false)
  }

  const checkValidation = {
    fullName: () => {
      if (!/[a-zA-Z]+\s+[a-zA-Z]+/g.test(fullName)) {
        setValidationChecks({ ...validationChecks, fullName: false })
        setValidationErrors({
          ...validationErrors,
          fullName: FULL_NAME_VALIDATION_ERROR,
        })
      }
    },
    email: () => {
      if (!EMAIL_VALIDATION_REGEXP.test(email)) {
        setValidationChecks({ ...validationChecks, email: false })
        setValidationErrors({
          ...validationErrors,
          email: EMAIL_VALIDATION_ERROR,
        })
      }
    },
    phone: () => {
      if (phone.length < 1) {
        setValidationChecks({ ...validationChecks, phone: false })
        setValidationErrors({
          ...validationErrors,
          phone: MOBILE_PHONE_VALIDATION_ERROR,
        })
      }
    },
  }

  const entryValidator = {
    fullNameValidator: value => {
      if (/^[a-zA-Z ]*$/.test(value)) {
        setFullName(value)
        setValidationErrors({ ...validationErrors, fullName: "" })
        if (/[a-zA-Z]+\s+[a-zA-Z]+/g.test(value))  {
          setValidationChecks({ ...validationChecks, fullName: true })
          setValidationErrors({ ...validationErrors, fullName: "" })
        } else {
          setValidationChecks({ ...validationChecks, fullName: false })
        }

        if (value.length === 0) {
          setValidationErrors({
            ...validationErrors,
            fullName: FULL_NAME_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          fullName: LATIN_CHARACTERS_VALIDATION_ERROR,
        })
      }
    },

    emailValidator: value => {
      if (/^[a-zA-Z0-9@.]*$/.test(value)) {
        setEmail(value)
        setValidationErrors({ ...validationErrors, email: "" })

        if (EMAIL_VALIDATION_REGEXP.test(value)) {
          setValidationChecks({ ...validationChecks, email: true })
        } else {
          setValidationChecks({ ...validationChecks, email: false })
        }

        if (value.length === 0) {
          setValidationErrors({
            ...validationErrors,
            email: EMAIL_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          email: LATIN_CHARACTERS_VALIDATION_ERROR,
        })
      }
    },

    phoneValidator: value => {
      if (/^[0-9]*$/.test(value)) {
        setPhone(value)
        setValidationErrors({ ...validationErrors, phone: "" })

        if (value.length > 0) {
          setValidationChecks({ ...validationChecks, phone: true })
        } else {
          setValidationChecks({ ...validationChecks, phone: false })
          setValidationErrors({
            ...validationErrors,
            phone: MOBILE_PHONE_VALIDATION_ERROR,
          })
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          phone: DIGITS_VALIDATION_ERROR,
        })
      }
    },
  }

  const isDisabled = !Object.values({ ...validationChecks, agreement }).every(check => check)

  const handleForm = () => {
    if (!isDisabled) {
      if (cvState.cv === null) {
        setCvState({ ...cvState, showErrorMessageForCV: true })
        return
      }

      SendCV.sendCV(
        { fullName, email, phone, vacancyId, agreement, ...cvState },
        () => {
          clearForm()
          setShowMessage(true)
        }
      )
    }
  }

  return {
    fullName,
    email,
    phone,
    cvState,
    isDisabled,
    entryValidator,
    checkValidation,
    validationErrors,
    setAgreement,
    setCvState,
    handleForm
  }
}

export default useFormConfiguration
