import styled from "styled-components"

export const StyledFormWrapper = styled.form`
  max-width: 26.25rem;
  width: 100%;

  @media only screen and (max-width: 1350px) {
    max-width: 100%;
  }
`

export const StyledInputsContainer = styled.div`
  margin-bottom: 2rem;
`

export const StyledFormBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin: 0;
  align-items: flex-start;

  @media only screen and (max-width: 1350px) {
    row-gap: 1rem;
  }
`