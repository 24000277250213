import React, { useMemo, useState } from "react";
import "./SendCV.scss";
import CircleBLur from "@common/CircleBlur/CircleBlur";
import { navigate } from "gatsby";
import { THANK_YOU_PATH, MOBILE_MAX_WIDTH } from "@constants";
import useWindowDimensions from "@hooks/useWindowDimensions";
import Form from "./Form";

const SendCV = ({ vacancyId }) => {
    const { width } = useWindowDimensions();
    const [showMessage, setShowMessage] = useState(false);
    const isMobile = useMemo(() => width <= MOBILE_MAX_WIDTH, [width]);

    return (
        <div className="send-cv">
            <CircleBLur width="635px" right="-120px" height="635px" />
            <div className="send-cv__content-container">
                <h1 className="send-cv__heading">
                    Build your career&nbsp;
                    {isMobile && <br />}
                    with Impressit
                </h1>
                <div className="send-cv__container">
                    {showMessage ? (
                        navigate(THANK_YOU_PATH, {
                            state: { isCareers: true },
                        })
                    ) : (
                        <Form
                            vacancyId={vacancyId}
                            setShowMessage={setShowMessage}
                        />
                    )}
                    <div className="send-cv__info-container">
                        <div className="send-cv__info-address-container">
                            <span className="send-cv__info-address">
                                Address
                            </span>
                            <p className="send-cv__info">
                                5 Zarytskykh St, Lviv, Ukraine, 
                                79011
                            </p>
                        </div>
                        <div className="send-cv__mobile-container">
                            <div className="send-cv__mobile-info">
                                <span className="send-cv__mobile-info-description">
                                    Phone
                                </span>
                                <span className="send-cv__mobile-number">
                                    +38 096 077 0011
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SendCV;
